import React from 'react'
import styled from 'styled-components'
import Bio from './Bio'
import Content from './Content'
import { Link } from "gatsby"
import { colors, media } from '../tokens'
import { Container, Button, Col, Row, Dropdown } from "react-bootstrap"
import '../utils/FontAwesome'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Image from 'gatsby-image'
import useSiteImages from '../hooks/use-site-images'

const AssetBoxWrapper = styled(Col)`

    margin: 20px 0;

    .boxImage {
        opacity: 0.7;
        display: block;
        width: 100%;
        height: auto;
        transition: .5s;
        backface-visibility: hidden;
    }
  
    .description {
        color: white;
        font-size: 20px;
        text-align: justify;
    }
    :hover .description {
        opacity: 1;
    }

    .title {
        text-decoration: none;
    }
    :hover .title {
        text-decoration: underline;
        text-decoration-color: ${colors.dypPurpleLight};
    }

    .boxButton{
        width:100%;
    }

    .overlay-parent{
        position: absolute;
        top: 0;
        left: 0px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 25px;
        transition: .5s ease;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(64, 73, 77, 0.8))
    }

    .overlay-top {
        position: absolute;
        top: 0;
        left: 0px;
        right: 0px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        transition: .5s ease;
    }

    .overlay-bottom {
        position: absolute;
        bottom: 0;
        left: 0px;
        right: 0px;
        overflow: hidden;
        width: 100%;
        height: 0;
        transition: .5s ease;
    }

    :hover .overlay-parent {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(34, 33, 37, 0.8))
    }
    :hover .overlay-top {
        height: 50%;
    }
    :hover .overlay-bottom {
        height: 50%;
    }

    .relative-parent{
        position: relative;
    }

`

const Title = styled.h2`
    
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    padding: 20px;
    position: absolute;
    bottom: 10px;
    font: fantasy;
    font-size: 1.5rem;
    font-style: bold;
`

const Description = styled.div`
    padding: 20px;
`

const BoxImage = styled(Image)`
    border-radius: 25px;
    width:100%;
`

const AssetBoxButton = (props)=>{
    const {children, link} = props;

    if(!link || link.startsWith("/")){
        return (
        <Link to={link} className="boxButton">{children}</Link>
        ) 
    }

    return(
        <a href={link} className="boxButton">{children}</a>
    )
}

const AssetBox = props => {
    const { assetInfo } = props
    const { title, boxImage, link, description } = assetInfo
    const { fluid } = useSiteImages(boxImage)
    fluid.aspectRatio = 1.5;

    return (
        <AssetBoxWrapper lg={6}>
            <div className="relative-parent">
                <AssetBoxButton link={link} >
                <BoxImage className="boxImage" 
                    alt={boxImage}
                    fluid={fluid} 
                    objectFit="cover"
                    objectPosition="50% 50%"/>
                <div className="overlay-parent">
                    <div className="overlay-top">
                        <Title className="title">
                            {title}
                        </Title>
                    </div>
                    <div className="overlay-bottom">
                        <Description className="description">
                            {description}
                        </Description>
                    </div>
                </div>
                </AssetBoxButton>
            </div>
        </AssetBoxWrapper>
    )
}

export default AssetBox